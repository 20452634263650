import './App.css';
import AppRouter from './routes/routes';
import BackgroundContainer from './components/container';
import HeaderGeneric from './components/HeaderGeneric';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
      <AuthProvider>
        <BackgroundContainer>
          <HeaderGeneric />
          <AppRouter />
        </BackgroundContainer>
      </AuthProvider>
  );
}

export default App;
