import React, { ReactNode } from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/landing/background-lines.png';

interface BackgroundContainerProps {
  children: ReactNode;
}

const BackgroundContainer: React.FC<BackgroundContainerProps> = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

export default BackgroundContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(0deg, rgba(13, 13, 20, 0.8), rgba(0, 51, 102, 0.8)), url(${BackgroundImage});
  background-size: cover;
  background-blend-mode: overlay;
  color: white;
  text-align: center;
  padding: 2rem;
`;
