import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import MyLogo from '../../assets/landing/single-logo.png';
import Mybrand from '../../assets/landing/logo-blanco.png';

const HeaderGeneric: React.FC = () => {
  return (
    <HeaderContainer>
      <Logo>
        <img src={MyLogo} alt="Logo" />
        <img className="brand" src={Mybrand} alt="brand" />
      </Logo>
    </HeaderContainer>
  );
};

export default HeaderGeneric;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  img {
    height: 50px;
    transition: height 0.3s ease;

    @media (max-width: 768px) {
      height: 40px;
    }
  }

  .brand {
    @media (max-width: 768px) {
      height: 40px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const ButtonStyled = styled(Button)`
  padding: 0.5rem 2rem;
  font-size: 1rem;
  color: white;
  border-radius: 20px;
  min-width: 120px;
  &:hover {
    background-color: #21a1f1;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
`;
