import React from 'react';
import ResetPassword from '../../ResetPassword';
import styled from 'styled-components';
import { Card } from '@mui/material';

export type ResetPasswordViewProps = {
}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = ({}) => {
  return (
    <Container>
      <StyledCard>
        <ResetPassword />
      </StyledCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vh
  height: 100vh;
  box-sizing: border-box;
`;

const StyledCard = styled(Card).attrs({
  sx: {
    borderRadius: 3,
  },
})`
  width: 100%;
  max-width: 450px;
  height: 80vh;
  max-height: 550px;
  background: linear-gradient(to bottom, rgba(49, 101, 152, 0.5), rgba(24, 26, 32, 0.5)) !important;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%);
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }

  .MuiCardContent-root {
    position: relative;
    z-index: 3;
  }
`;

export default ResetPasswordView;
