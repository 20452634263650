export const validatePassword = (password: string) => {
    if (password.length < 6) {
      return 'La contraseña debe tener al menos 6 caracteres';
    }
    return '';
  };
  
  export const validateConfirmPassword = (password: string, confirmPassword: string) => {
    if (confirmPassword.length < 6) {
      return 'La confirmación de contraseña debe tener al menos 6 caracteres';
    }
    if (password !== confirmPassword) {
      return 'Las contraseñas no coinciden';
    }
    return '';
  };
  