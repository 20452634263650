import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../components/pages/Home";
import ResetPasswordView from "../components/pages/ResetPasswordView";
import ConfirmResetView from "../components/pages/ConfirmResetView";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/reset-password" element={<ResetPasswordView />}></Route>
        <Route path="/success-reset" element={<ConfirmResetView />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRouter;
