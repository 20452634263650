import React from 'react';
import './Home.css';
import HeaderGeneric from '../../HeaderGeneric';

export type HomeProps = {
	// types...
}

const Home: React.FC<HomeProps>  = ({}) => {
	return (
		<>
		</>
	);
};

export default Home;
