import React from 'react';
import styled from 'styled-components';
import { Card } from '@mui/material';
import Football from '../../../assets/drawer/football.png';

const ConfirmResetView: React.FC = () => {
  return (
    <Container>
      <StyledCard>
        <DrawerHeader>
          <img src={Football} alt="Drawer Logo" />
        </DrawerHeader>
        <Title>¡Su contraseña se actualizó correctamente!</Title>
      </StyledCard>
    </Container>
  );
};

const DrawerHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 13px 13px 0 0 ;
  }
`;

const Title = styled.div`
  text-align: center;
  margin: 25px;
  font-size: 24px;
  color: #A5D21D;
  margin-top: 100px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 100%;
  box-sizing: border-box;
`;

const StyledCard = styled(Card).attrs({
  sx: {
    borderRadius: 3,
  },
})`
  width: 100%;
  max-width: 450px;
  height: 50vh;
  max-height: 550px;
  background: linear-gradient(to bottom, rgba(49, 101, 152, 0.5), rgba(24, 26, 32, 0.5)) !important;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%);
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }

  .MuiCardContent-root {
    position: relative;
    z-index: 3;
  }
`;

export default ConfirmResetView;
