import axios, { AxiosRequestConfig, AxiosError } from "axios";

export const Interceptor = axios.create({
  baseURL: process.env.REACT_APP_GAMECHANGER_URL,
});

const Request = (config: any): AxiosRequestConfig => {
  console.log(process.env);
  if (config.url) {
    console.log("Enviando al servidor...");
  }
  return config;
};

Interceptor.interceptors.request.use(
  (config: any) => {
    config = Request(config);
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);
